<template>
  <el-scrollbar>
    <div class="page-container">
      <header>
        <header-bar/>
      </header>
      <banner-bg/>
      <latest-note :note-list="noteList"/>
      <div class="sys-link-area">
        <el-row class="link-container-row">
          <el-col :xs="1" :sm="1" :md="1" :lg="2" :xl="2"></el-col>
          <el-col :xs="22" :sm="22" :md="22" :lg="20" :xl="20">
            <div class="link-container">
              <div class="link-item" v-for="(item) in sysLinkList" :key="item.id"
                   @click="handleLinkClick(item.link)" :style="{visibility: item.hidden?'hidden':'visible'}">
                <div class="link-title">
                  <div>{{ item.serviceName }}</div>
                  <div class="vpn" v-if="item.useVPN">VPN访问</div>
                </div>
                <div class="image-container">
                  <div class="arrow-container">
                    立即前往
                    <i class="el-icon-right"></i>
                  </div>
                  <img :src="item.serviceIconPath" alt="系统图标">
                </div>
              </div>
            </div>
          </el-col>
          <el-col :xs="1" :sm="1" :md="1" :lg="2" :xl="2"></el-col>
        </el-row>
      </div>
    </div>
  </el-scrollbar>
</template>

<script>
import HeaderBar from '@/components/header-bar/HeaderBar'
import LatestNote from '@/components/latest-note/LatestNote'
import BannerBg from '@/components/banner/BannerBg'
import { queryPagination } from '@/service/api/notification/notification'
import { querySaPagination } from '@/service/api/sys-access/system-access'

export default {
  name: 'Index',
  components: {
    HeaderBar,
    LatestNote,
    BannerBg
  },
  data() {
    return {
      noteList: [
        // {
        //   id: 1,
        //   noticeTitle: '【系统迁移】因工时系统服务器迁移，工时系统访问地址即日变更为：http//www.toguide.cn:1880，请周知！'
        // },
        // {
        //   id: 2,
        //   noticeTitle: '【系统恢复】图观知识库计划于2022年11月11日恢复使用，敬请期待！'
        // },
        // {
        //   id: 3,
        //   noticeTitle: '【新增制度】《图观（天津）数字科技有限公司代码审查规范》已发布，请研发人员及时访问图观开发者平台进行查看并践行！'
        // }
      ],
      sysLinkList: [
        {
          id: 1,
          serviceName: '工时系统',
          link: 'https://hour.toguide.cn',
          serviceIconPath: require('@/assets/images/index/pic-gs-cl.webp'),
          useVPN: false,
          hidden: false
        },
        {
          id: 2,
          serviceName: 'TCP平台',
          link: 'http://192.168.3.21:18707/',
          serviceIconPath: require('@/assets/images/index/pic-pct-cl.webp'),
          useVPN: true,
          hidden: false
        },
        {
          id: 3,
          serviceName: '“观之”知识共享平台',
          link: 'http://192.168.3.105:18080/',
          serviceIconPath: require('@/assets/images/index/pic-zsk-cl.webp'),
          useVPN: true,
          hidden: false
        }
        // {
        //   id: 4,
        //   serviceName: '会议助手',
        //   link: 'https://192.168.3.106:16889/',
        //   serviceIconPath: require('@/assets/images/index/pic-hyzs-cl.webp'),
        //   useVPN: true,
        //   hidden: true
        // }
      ],
      // 系统接入加载
      asLoading: false
    }
  },
  mounted() {
    this.getNotifications()
    // this.getAccessSystem()
  },
  methods: {
    // 各个系统链接点击事件
    handleLinkClick(link) {
      if (link) {
        window.open(link, '_blank')
      }
    },
    getNotifications() {
      const params = {
        status: '1'
      }
      queryPagination(params).then(res => {
        this.tableLoading = false
        if (res.data.status === 200) {
          this.noteList = res.data.data
        } else {
          this.$notify.error(res.data.msg || '服务异常，获取公告数据失败。')
        }
      }).catch(e => {
        this.tableLoading = false
        console.log(e)
      })
    },
    getAccessSystem() {
      this.asLoading = true
      querySaPagination({ status: '1' }).then(res => {
        this.tableLoading = false
        if (res.data.status === 200) {
          this.sysLinkList = res.data.data
        } else {
          this.$notify.error(res.data.msg || '服务异常，获取系统接入列表数据失败。')
        }
      }).catch(e => {
        this.tableLoading = false
        console.log(e)
      })
    }
  }
}
</script>

<style scoped lang="scss">
.page-container {
  min-width: 768px;
  min-height: 100vh;
  box-sizing: border-box;

  header {
    height: 60px;
    width: 100%;
    min-width: 768px;
    background-color: #d3ddf0;

    .header-container {
      min-width: 768px;
    }
  }

  .sys-link-area {
    width: 100%;
    min-width: 768px;
    //height: 400px;
    padding-top: 20px;

    .link-container-row {
      //min-height: 400px;
      display: flex;
      align-items: center;

      .link-container {
        width: 100%;
        //min-height: 400px;
        gap: 20px;
        display: flex;
        transform: scale(1);

        .link-item {
          background-image: linear-gradient(180deg, rgba(255, 255, 255) 23%, #D2E3FB 99%);
          border-radius: 10px;
          flex: 1;
          //min-width: 336px;
          //height: 300px;
          aspect-ratio: 43 / 30;
          //height: 36vh;
          margin-bottom: 3%;
          display: flex;
          flex-direction: column;
          cursor: pointer;
          padding: 60px 0 0 40px;
          box-sizing: border-box;
          justify-content: space-between;
          position: relative;

          &::after {
            content: '';
            width: calc(100% + 4px);
            height: calc(100% + 4px);
            position: absolute;
            top: -2px;
            left: -2px;
            z-index: -1;
            border-radius: 12px;
            background: #fff;
          }

          &:hover {

            &::after {
              background-image: linear-gradient(#7EAEFF 78%, #4876FF 99%);
            }

            .link-title {
              color: #104EFE;
            }

            .image-container {
              .arrow-container {
                color: #0042FF;
              }

              img {
                transform: translateY(-10px);
                opacity: 1;
              }

            }
          }

          .link-title {
            font-size: 28px;
            color: #000000;
            letter-spacing: 0;
            font-weight: 500;
            position: relative;

            .vpn {
              position: absolute;
              font-size: 14px;
              margin-top: 10px;
              color: #999
            }
          }

          .image-container {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;

            img {
              opacity: 0.5;
              transition: opacity 0.8s, transform 0.8s;
            }

            @media screen and (max-width: 1280px) {
              img {
                width: 120px;
              }
            }

            .arrow-container {
              padding-bottom: 5vh;
              font-size: 18px;
              color: #0042FF99;
              letter-spacing: 0;
              font-weight: 400;
            }
          }
        }
      }
    }
  }
}
</style>
<style lang="scss" scoped>
::v-deep.el-scrollbar {
  height: 100vh;
  width: 100%;
  min-width: 768px;

  .el-scrollbar__wrap {
    min-width: 768px;
    overflow-x: hidden;
  }
}
</style>
